import { FC, useContext } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from 'react-router-dom'

// import Logo1 from "@/public/assets/header-logo1.png";
import "./header.css";
import { useLocation } from "react-router-dom";
import IndexContext from "@/layout/index.context";
// function Header() {
const Header: FC = () => {
  const location = useLocation();
  const { setIndex } = useContext(IndexContext);

  return (
    <div className={
      location.pathname === "/faqs" ? "main-header-faq" : "main-header"
    } >
      <Navbar
        expand="lg"
        className="nav-main"
      >
        <Navbar.Brand>
          <a target="_blank" href="https://app.cultdao.io/" rel="noreferrer">
            <img
              alt="Logo"
              src="/assets/header-logo1.png"
              className="header-logo1"
            />
          </a>
          <Nav.Link onClick={() => setIndex(0)} as={Link} to="/"  >
            <img
              alt="Logo"
              src="/assets/header-logo2.png"
              className="header-logo2"
            />
          </Nav.Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Navbar.Brand>
        {/* </div> */}

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="d-flex justify-content-end ">
            <Nav.Link as={Link} to="/" onClick={() => setIndex(0)}>HOME</Nav.Link>
            <Nav.Link as={Link} to="/" onClick={() => setIndex(3)}>TOKEN ECONOMY</Nav.Link>
            <Nav.Link as={Link} to="/faqs">FAQS</Nav.Link>
            <a
              className="text-white nav-link-other"
              target="blank"
              href="/manifesto"
            >
              MANIFESTO
            </a>
            <a
              className="text-white nav-link-other"
              target="blank"
              href="/rvlt"
            >
              RVLT RULES
            </a>
            <a
              target="blank"
              href="https://revolt.cultdao.io/"
              className="header-last-logo"
            >
              <img
                alt="Logo"
                src="/assets/header-logo3.png"
                className="header-logo3"
              />
            </a>
          </Nav>
        </Navbar.Collapse>
        {/* </div> */}
      </Navbar>


    </div>
  );
}

export default Header;
