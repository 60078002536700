/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Header from "@/layout/default/header/header";
import Footer from "./footer/footer";
// import "./default/default.css";

const FaqLayout = ({ children }: any) => {
    return (

        <div className="defualt-layout" >
            <Header />
            <main>{children}</main>
            <Footer />
        </div>
    );

};

export default FaqLayout;
