import { FC, useContext } from "react";
import "./home.css";
import { Carousel } from "react-bootstrap";
import IndexContext from "@/layout/index.context";

const Index: FC = () => {
  const { index, setIndex } = useContext(IndexContext); //slider state manage

  const handleSelect = (selectedIndex: number, e: any) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="home">
      <Carousel activeIndex={index} onSelect={handleSelect} indicators={false} className="carousel-main">
        {/* first slide  */}
        <Carousel.Item >
          <div className="d-flex slider-main-sec justify-content-center align-items-center">
            <div className="slider-image-sec">
              <img
                className="w-100 slide-image"
                src="/assets/slide1.png"
                alt="First slide"
              />
            </div>
            <div className="slider-content-sec text-light slide-first">
              <h1 className="slider-content-main-title animated zoomIn">
                Investing in the revolution
              </h1>
              <p className="animated zoomIn">
                The purpose of CULT is to empower and fund those building and
                contributing towards our decentralized future. Our society is
                built to make it as difficult as possible to break away from
                societal, economic and other norms.
              </p>
              {/* <Button variant="danger">LEARN MORE</Button> */}
            </div>
          </div>
        </Carousel.Item>
        {/* second slide */}
        <Carousel.Item >
          <div className="d-flex slider-main-sec justify-content-center align-items-center">
            <div className="slider-image-sec2">
              <img
                className=" w-100 slide-image"
                src="/assets/slide2.png"
                alt="Second slide"
              />
            </div>
            <div className="slider-content-sec text-light">
              <h1 className="slider-content-main-title">
                FIGHT AGAINST CENTRALIZATION
              </h1>
              <p>
                We give funding to those who are ready to break the chains and
                have an idea that can change the world.
              </p>
              {/* <Button variant="danger">GO FURTHER</Button> */}
            </div>
          </div>
        </Carousel.Item>
        {/* third slide */}
        {/* <Carousel.Item className="d-flex">
          <div className="d-flex slider-main-sec justify-content-center align-items-center">
            <div className="slider-image-sec22">
              <img
                className="w-100 slide-image-sec2"
                src="/assets/slide3.png"
                alt="Third slide"
              />
            </div>
            <div className="slider-content-sec text-light ml-8">
              <h1 className="slider-content-main-title ">
                A BURNING MECAHNISM
              </h1>
              <p>
                The CULT token brings forth this change. Once staked into the
                DAO, users can vote with dCULT, which is name of staked CULT.
              </p>
              {/* <Button variant="danger">REDISTRIBUTION</Button>
            </div>
          </div>
        </Carousel.Item> */}
        {/* forth slides */}
        <Carousel.Item >
          <div className="d-flex slider-main-sec justify-content-center align-items-center">
            <div className="slider-image-sec2">
              <img
                className=" w-100 slide-image"
                src="/assets/slide4.png"
                alt="Second slide"
              />
            </div>
            <div className="slider-content-sec text-light">
              <h1 className="slider-content-main-title">FULL DISTRIBUTION</h1>
              <p>
                When investee protocols tokens vest, having dCULT allows users
                to claim the rewards that are sent to the DAO
              </p>
              {/* <Button variant="danger">TOKEN ECONOMICS</Button> */}
            </div>
          </div>
        </Carousel.Item>
        {/* fifth slide  */}
        <Carousel.Item
          style={{
            height: "77vh",
            position: "fixed",
            // zIndex: "-1", //not clickable
            // zIndex: "999999",//it hides header and footer component
            top: "0px",
          }}
        >
          {/* <video
                className="slider-video"
                src="/assets/slide-5.mp4"
                loops
                autoPlay
                muted
              // loading="lazy"
              ></video> */}

          <video
            controls
            muted
            autoPlay
            loop
            style={{
              objectFit: "cover",
              backgroundSize: "cover",
              opacity: 1,
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              display: "block",
              height: "100vh",
            }}
            preload="auto"
          >
            <source src="/assets/slide-5.mp4" type="video/mp4" />
          </video>
          {/* <iframe title="embedsPage" className="responsive-iframe embed-responsive-item slider-video" src="/assets/slide-5.mp4"
            // style={{ margin: "0px !important", objectFit: "cover", backgroundSize: "cover", opacity: 1, width: "100%", position: "absolute", top: 0, left: 0, display: "block" }}
            allowFullScreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
          ></iframe> */}
        </Carousel.Item>

      </Carousel>
    </div>
  );
}

export default Index;