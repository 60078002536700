import React, { useEffect, useState } from "react";
import Header from "./default/header/header";
import Footer from "./default/footer/footer";
import "./default/default.css";
import { IndexContextProvider } from "./index.context";
import { isMobile } from 'react-device-detect';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DefaultLayout = ({ children }: any) => {
    const [index, setIndex] = useState(0);//slider state manage
    const [audioPlay, setAudioPlay] = useState(false);

    const audio = new Audio("../../music.mp3");
    const audioRef = React.useRef<HTMLAudioElement>(audio);
    useEffect(() => {
        // storing audio state
        localStorage.setItem("audio", JSON.stringify(audioPlay));
        audioRef.current.pause();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function play() {
        setAudioPlay(true);
        audioRef.current.play();
    }

    function pause() {
        setAudioPlay(false);
        audioRef.current.pause();
    }


    return (
        <IndexContextProvider value={{ index, setIndex }} >
            <div className="defualt-layout" >
                <video id="videobg" autoPlay loop muted>
                    <source src="/assets/Stormy-Sky2.mp4" type="video/mp4" />
                </video>
                <Header />
                <main>
                    <>
                        {children}
                        { isMobile && <div className="play-icon-container playAndPauseButtonDivStyle">
                            {audioPlay === true ? (
                                <img src="/assets/play.png" alt="img" onClick={() => pause()} width={42} />
                            ) : (
                                <img src="/assets/pause.png" alt="img" onClick={() => play()} width={42} />
                            )}

                            <audio autoPlay preload="none" ref={audioRef} id="audioPlay">
                                <source src="/assets/audio_bg.mp3" type="audio/mpeg" />
                            </audio>
                        </div> }
                    </>
                </main>
                <Footer />
            </div>
        </IndexContextProvider>
    );

};

export default DefaultLayout;
