import React from "react";
import "@/App.css";
import Home from "@/components/home/home";
// import { QueryClient, QueryClientProvider } from "react-query";
import DefaultLayout from "./layout";
import { Route, Routes } from "react-router";
import {
  BrowserRouter
} from "react-router-dom";
import FaqLayout from "@/layout/default/FaqLayout";
import Faq from "@/components/faq/faq";

function App() {
  // const queryClient = new QueryClient();
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }, []);

  return (
    // <QueryClientProvider client={queryClient}>

    <BrowserRouter>
      {/* {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : ( */}
      <Routes>
        <Route path="/" element={<DefaultLayout >
          <Home />
        </DefaultLayout>} />
        <Route path="/faqs" element={<FaqLayout >
          <Faq />
        </FaqLayout>} />
      </Routes>
      {/* )} */}
    </BrowserRouter>
    // </QueryClientProvider>
  );
}

export default App;
