import { createContext } from "react";

export  interface IndexContextType {
  index: number;
  setIndex: (index:number) => void;
}
const IndexContext = createContext<IndexContextType>({ index: 0, setIndex: index => console.log('no index provider')});
export const IndexContextProvider = IndexContext.Provider;
export default IndexContext;

