import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./faq.css";

function Faq2() {
    return (
        <div className="faq2-main">
            <h1 className="faq-tilte-main text-light">
                FAQ & explanation for protocols seeking funding from CULT DAO
            </h1>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        What is the added value of an investment from CULT DAO?
                    </Accordion.Header>
                    <Accordion.Body>
                        Community and your very own Cult. Where else can you find a
                        ready and waiting community who already share your values and
                        are interested and fully aligned to your goals? We are your shillers,
                        your marketiers and your mercenaries in this fight against centralisation.
                        We will champion you to the ends of the earth.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        How do I submit a protocol for funding from the DAO?
                    </Accordion.Header>
                    <Accordion.Body>
                        To submit proposals to the DAO you must enlist the help of a Guardian,
                        or multiple Guardians. Only they can put your proposal forward, unless
                        you yourself are one of the top 50 dCULT holders. Your protocol must conform
                        to at least two of the three below guidelines,
                        it is for the Guardians & then the Many to decide if they believe your protocol matches.<br /><br />
                        <ul className="list-text">
                            <li> Fight Against Centralisation,</li>
                            <li>Further the Cause of Decentralisation</li>
                            <li>Directly Benefit a Noble Cause.</li>
                        </ul>
                        Your Guardians can only take you to the gates, the Many will be the ones who vote on your proposal.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        What does my proposal have to contain?
                    </Accordion.Header>
                    <Accordion.Body>
                        1) The total supply of the investee protocol token<br />
                        2) The percentage of the total supply being offered in return for 13 ETH investment<br />
                        3) The tokenomics<br />
                        4) The audit of the token and any contracts if built<br />
                        5)<b> The burn and distribution plan*1</b><br /><br />
                        <u>  Total Supply </u>– Your token cannot have any mint functionality and the audit must prove this<br /><br />
                        <u>Percentage of total supply being offered in return for investment – </u>
                        If you have pre priced rounds then great, just calculate the percentage a 13 ETH
                        purchase buys CULT at the price of the round you are raising for. It is up to the
                        Many to decide what is approved, but the DAO is designed for pre seed and seed rounds of funding.<br /><br />
                        <u> The Tokenomics – </u>You must provide details of where and who owns or is anticipated to
                        own how much of your token and what price they paid for it. You must also provide vesting
                        details, any TGE unlock & anything else that may impact the decisions of the DAO. If the DAO
                        does not think your data is adequate then your proposal will be rejected, this could impact your chances of
                        funding if you have to resubmit so please do make sure your numbers are clear.<br /><br />
                        <u>The audit of the token and any contracts if built – </u>The full report should be submitted in
                        the proposal for the token and also for any other contracts if applicable.<br /><br />
                        <u>The burn and distribution plan –</u> The burn and distribution plan is the
                        vesting schedule for your protocol token. The vesting schedule can be daily, weekly,
                        fortnightly or monthly distributions, but it cannot be vested over longer than 18 months at the very maximum.
                        For an example, let us use a fake protocol <b>ABC DAO</b>.<br /><br />
                        Lets say ABC DAO promises 1.2% of it’s $ABC token, vesting once a month for 12 months.
                        On the repayment date, rather than sending the token of the investee protocol, like a
                        normal VC funded protocol would, the investee company instead swaps 0.1% of its supply
                        (1.2% over 12 months) of $ABC for $CULT. ABC DAO then sends half of the cult to a burn wallet,
                        and the other half to the DAO, which is paid out to dCULT holders.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        What measures are in place to ensure that my crypto is protected?
                    </Accordion.Header>
                    <Accordion.Body>
                        Our contracts and token are & is fully audited, liquidity is locked, the keys burnt & the contracts non upgradeable
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                        What is decentralised venture capitalism?
                    </Accordion.Header>
                    <Accordion.Body>
                        The purpose of CULT is to empower and fund those building and contributing towards
                        our decentralised future, our society is built to make it as difficult as possible
                        to break away from societal, economic and other norms. We give funding to
                        those who are ready to break the chains and have an idea that can change our world.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>
                        What is a DAO?
                    </Accordion.Header>
                    <Accordion.Body>
                        A DAO is a Decentralised Autonomous Organization. The definition of which
                        is argued almost as much, and by almost as many, who squabble between themselves in left wing politics.
                        <br /><br />
                        A DAO in its purest form is what we have created with Cult DAO. Once
                        Cult DAO launches, it will function forever more with no human interference
                        beyond what has been coded for it to be influenced by, which is votes from
                        its holders. Nobody can dismantle the code and nobody can stop it collecting
                        funds and sending them to those furthering our cause, as
                        long as the token is traded & as long as people are passing proposals, CULT will live on.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>
                        Who are the Guardians and how are they chosen? What is their purpose?
                    </Accordion.Header>
                    <Accordion.Body>
                        The Guardians are the top 50 token holders, they are tasked with
                        safeguarding which proposals are sent to The Many to be voted upon.
                        Due to their larger weighting and token share, they are not allowed to influence
                        the proposals they bring before the Many, they may champion the proposal as much as they please
                        but only their voice may influence others, not their wealth.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>
                        Where does the money invested come from and how does CULT staking work?
                    </Accordion.Header>
                    <Accordion.Body>
                        1) Users interact & trade with the token as normal, 0.4% tax is taken (in the CULT token) on
                        each transaction, this means almost all transactions should clear on standard DEX slippage settings <br /><br />

                        2) This tax is collected in Cult DAOs treasury and builds up to a USD value which matches the
                        market value of 15.5 ethereum.<br /><br />

                        3) CULT can be staked for dCULT. The top 50 dCULT holders are The Guardians. All holders
                        below The Guardians have voting rights and these are The Many<br /><br />

                        4) The Guardians (top 50 holders) are the only users who can put proposals before the DAO,
                        but they cannot vote on them, these proposals must conform to all or the majority of these three criteria.<br /><br />
                        <b>Fight Against Centralisation,</b><br />
                        <b>Further the Cause of Decentralisation</b><br />
                        <b>Directly Benefit a Noble Cause.</b> <br /><br />
                        5) These proposals can be from anywhere, VCs, community members, politicians, anarchists,
                        socialists, the right wing, as long as they conform to the guidance above and it is a Guardian
                        who submits it, any proposal can be voted on by the Many.<br /><br />

                        6) A proposal must contain; the total supply of the investee protocol token, the percentage of
                        the total supply being offered in return for 13 ETH investment, the tokenomics, the audit of the
                        token and any contracts if built, the burn and distribution plan<br /><br />

                        7) The burn and distribution plan*1 is the vesting schedule for the investee protocols token.
                        For this example let us use a fake protocol ABC DAO. The vesting schedule can be daily, weekly,
                        fortnightly or monthly, but cannot be vested over longer than 18 months at the very maximum.<br /><br />

                        8) Lets say ABC DAO promises 1.2% of it’s $ABC token, vesting once a month for 12 months.
                        On the repayment date, rather than sending the token of the investee protocol, like a normal
                        VC funded protocol would, the investee company instead swaps 0.1% of its supply (1.2% over 12 months)
                        of $ABC for $CULT. ABC DAO then sends half of the cult to a burn wallet, and the other half to the DAO,
                        which is paid out to dCULT holders.<br /><br />

                        9) If a proposal has been approved, and the 15.5 CULT ETH value is hit, it will automatically send 13 ETH
                        to the proposal wallet address provided, and 2.5 ETH to a burn wallet.<br /><br />

                        10) If a proposal is not approved, the CULT will continue to build up past the 15.5 ETH level
                        but will auto send once a proposal is approved eventuallyv<br />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>
                        Why can the Guardians submit proposals to the DAO, but not vote on investment protocols?
                    </Accordion.Header>
                    <Accordion.Body>
                        The power in people, is stronger, than the people in power. This cannot be forgotten.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>
                        What type of decisions/ investments will CULT stakers pass votes on? Will projects only
                        be cryptocurrency/ blockchain based?
                    </Accordion.Header>
                    <Accordion.Body>
                        We leave the decisions, and the nature of the investee projects, open to the
                        Guardians and the Many. We offer only our vision & guidance on what we created the protocol to be used for.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                    <Accordion.Header>
                        Do I have to stake my CULT when an investment protocol is agreed upon or can I decide not to stake?
                    </Accordion.Header>
                    <Accordion.Body>
                        If you would like to vote, then you must be staked. You will also only receive rewards
                        from the investee companies if you are staked. However, you do not need to be staked to
                        benefit from CULT, every transaction furthers the cause and every burn decreases the supply
                        of the token, increasing the scarcity.


                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                    <Accordion.Header>
                        What criteria must be observed for a DAO proposal to be eligible for submission?
                    </Accordion.Header>
                    <Accordion.Body>
                        Our guidelines followed (Fight Against Centralisation, Further the Cause of
                        Decentralisation, Directly Benefit a Noble Cause.)<br /><br />
                        1) The total supply of the investee protocol token<br />
                        2) The percentage of the total supply being offered in return for 13 ETH investment<br />
                        3) The tokenomics<br />
                        4) The audit of the token and any contracts if built<br />
                        5) <b>he burn and distribution plan*1</b><br />

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                    <Accordion.Header>
                        What constitutes a ´Noble Cause´ in terms of investment?
                    </Accordion.Header>
                    <Accordion.Body>
                        “My fellow revolutionaries, liberation is a noble cause. We must fight to obtain it.”
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13">
                    <Accordion.Header>
                        Why do we burn 2.5 ETH of every 15.5 ETH raised?
                    </Accordion.Header>
                    <Accordion.Body>
                        We are not blind to the blockchain and crypto markets core attraction being t
                        he potential huge increases in price of a token picked by a user. We are also
                        wise to the previous financial systems tricks to keep the Many subdued and this
                        is why our burn system has been designed to make our economic model hyper deflationary,
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="14">
                    <Accordion.Header>
                        Why do we offer full distribution in the form of 50% burning and 50% proportional redistribution?
                    </Accordion.Header>
                    <Accordion.Body>
                        We the Many, who are for the people, put our faith IN the people. We fund those
                        willing to fight for our future and reward them with our gratitude, community
                        support and our trust. In turn, they sacrifice half of the tokens we have purchased
                        from them, and send them to a dead wallet. The other half are rewarded to the Many for
                        their contributions they have put forward for the cause by transacting in CULT.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="15">
                    <Accordion.Header>
                        From the 50% redistributed to CULT stakers, users can then stake the CULT again into dCULT.
                        What is the difference between CULT and dCULT OR What is dCULT?
                    </Accordion.Header>
                    <Accordion.Body>
                        dCULT is the “proof of stake” token. It is non transferable. Only the user who staked their cult
                        will benefit from dCULT. Owning a proportion of dCULT when profits are sent to the DAO allows you
                        to claim your reward which is paid our in proportion to your dCULT ownership.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="16">
                    <Accordion.Header>
                        Why is the investee token redistributed by converting it into CULT token? How will
                        the rates of disbursement be agreed upon?
                    </Accordion.Header>
                    <Accordion.Body>
                        The investee token is converted into CULT as it creates additional transactions, as well as
                        additional buying pressure in the market. The rate of disbursement is offered by the investee
                        protocol, a proposal can be brought about again once rejected and so if the cause of the rejection
                        is deemed to be due to an inadequate disbursement offer, the investee protocol can fix and make these amends.


                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}

export default Faq2;
