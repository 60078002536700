import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Faq2 from "./faq2";
import "./faq.css";

function Faq() {


    const scrollBottom = () => {
        window.scrollTo({ top: 1660, behavior: 'smooth' });
    };

    return (
        <div id="slider" className="faq-main">
            <div className="container">
                <div className="funding-sec d-flex justify-content-between text-light ">
                    <p>
                        CULT can be staked for dCULT. The top 50 dCULT holders are The
                        Guardians.
                        <br />
                        All holders below top 50 have voting rights and these are The Many
                    </p>
                    <div id="el1" className="el1 clickhere-text" onClick={scrollBottom}
                    >
                        {/* <a href="/"> */}
                        Are you Seeking Funding? <span className="click-text">Click Here  <img className="next-icon" src="/assets/next_icon.png" alt=">" /></span>

                        {/* </a> */}
                    </div>

                </div>
                <h1 className="faq-tilte-main text-light">FAQ & Explanations for CULT Users & token holders</h1>
                <Accordion color="white" defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>What is CULT?</Accordion.Header>
                        <Accordion.Body>
                            CULT is the tradable and liquid token of CULT DAO, transacting CULT
                            will contribute to the protocol by filling the DAO treasury slowly,
                            to fund investments into decentralised technologies. This is
                            achieved due to a 0.4% tax on all CULT transactions.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>What is dCULT?</Accordion.Header>
                        <Accordion.Body>
                            dCULT is just the “proof of stake token” for CULT. When you stake
                            your CULT into the DAO, you are given dCULT, this can be swapped
                            back at any time into the amount of CULT you staked originally, plus
                            any rewards that were given to the DAO in the time period you owned
                            dCULT.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            What makes CULT different from other decentralised investment
                            platforms?
                        </Accordion.Header>
                        <Accordion.Body>
                            There is no control, no human interference whatsoever, we cannot
                            change Cult, we cannot access the liquidity, we cannot upgrade or
                            edit the smart contracts or the token. Cult is different because it
                            cannot be stopped, not by the Guardians, the developers, the
                            government, regulation or anybody. They can destroy the servers but
                            the code lives on, the investments into Decentralised technologies
                            remain.
                        </Accordion.Body>
                    </Accordion.Item>{" "}
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                            What is the idea behind CULT and how did it begin?
                        </Accordion.Header>
                        <Accordion.Body>
                            “Beneath this mask there is more than flesh, beneath this mask there
                            is an idea, and ideas are bulletproof.” <br />
                            <br />
                            CULT serves to fast forward the collapse of the old financial
                            system, to end the tyranny of sovereign nations and central banks.
                            The current financial system serves to keep the people poor, a
                            society built on debt needs inflation to function, and inflation
                            serves to rob the poor and allow them to never catch up with their
                            overlords in the ruling class.
                            <br />
                            <br /> CULT began when Vasco de Gama sailed around the Cape of Good
                            Hope and discovered the route to India. CULT began when in 1526 the
                            first transatlantic slave voyage anchored in Brazil. CULT began and
                            is born of oppression, of meaningless life, the slavery and chains
                            which bind you and every single one of us, if you read this and
                            believe that you are free then you are more chained than us, true
                            freedom comes with the realisation and acceptance that you exist as
                            a function and cog in a central banks ideal economic function. A
                            central bank’s primary focus is maximum employment, yet we are
                            automating jobs so fast that we are creating bullshit jobs just to
                            keep us enslaved, Barack Obama’s explicit justification in 2009 for
                            sticking with the US private health insurance system, was otherwise,
                            millions of form-filling jobs would be lost? <br />
                            <br />
                            Automation of ones job should mean freedom. Freedom however cannot
                            exist under the current economic and financial system. I repeat,
                            true freedom comes with the realisation and acceptance that you
                            exist as a function and cog in a central banks ideal economic
                            function. The system is designed to keep us enslaved, we can’t break
                            out and live in the woods, well, not all of us, our body remains
                            chained but our heart is now free, so USE CULT, every transaction,
                            deposit, and movement of the token allows you to contribute & fast
                            forward economic & societal change. You can fight from within until
                            you get out.
                        </Accordion.Body>
                    </Accordion.Item>{" "}
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>How can I get involved in CULT?</Accordion.Header>
                        <Accordion.Body>
                            “You are not your job, you’re not how much money you have in the
                            bank. You are not the car you drive. You’re not the contents of your
                            wallet. You are not your fucking khakis. You are the all singing,
                            all dancing crap of the world.”
                            <br />
                            <br />
                            “You are not your job, you’re not how much money you have in the
                            bank. You are not the car you drive. You’re not the contents of your
                            wallet. You are not your fucking khakis. You are the all singing,
                            all dancing crap of the world.”
                            <br />
                            <br />
                            We are all prostitutes: Whether we sell what’s between our legs; or
                            what’s between our ears. There is only one winner, and that is the
                            oppressor.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>
                            What measures are in place to ensure that my crypto is protected?
                        </Accordion.Header>
                        <Accordion.Body>
                            Our contracts and token are & is fully audited, liquidity is locked,
                            the keys burnt & the contracts non upgradeable
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>
                            What is decentralised venture capitalism?
                        </Accordion.Header>
                        <Accordion.Body>
                            The purpose of CULT is to empower and fund those building and
                            contributing towards our decentralised future, our society is built
                            to make it as difficult as possible to break away from societal,
                            economic and other norms. We give funding to those who are ready to
                            break the chains and have an idea that can change our world.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>What is a DAO?</Accordion.Header>
                        <Accordion.Body>
                            A DAO is a Decentralised Autonomous Organization. The definition of
                            which is argued almost as much, and by almost as many, who squabble
                            between themselves in left wing politics.
                            <br />
                            <br />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header>
                            Who are the Guardians and how are they chosen? What is their
                            purpose?
                        </Accordion.Header>
                        <Accordion.Body>
                            The Guardians are the top 50 token holders, they are tasked with
                            safeguarding which proposals are sent to The Many to be voted upon.
                            Due to their larger weighting and token share, they are not allowed
                            to influence the proposals they bring before the Many, they may
                            champion the proposal as much as they please but only their voice
                            may influence others, not their wealth.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header>
                            Where does the money invested come from and how does CULT staking
                            work?
                        </Accordion.Header>
                        <Accordion.Body>
                            1) Users interact & trade with the token as normal, 0.4% tax is
                            taken (in the CULT token) on each transaction, this means almost all
                            transactions should clear on standard DEX slippage settings <br />
                            <br />
                            2) This tax is collected in Cult DAOs treasury and builds up to a
                            USD value which matches the market value of 15.5 ethereum. <br />
                            <br />
                            3) CULT can be staked for dCULT. The top 50 dCULT holders are The
                            Guardians. All holders below The Guardians have voting rights and
                            these are The Many
                            <br />
                            <br /> 4) The Guardians (top 50 holders) are the only users who can
                            put proposals before the DAO, but they cannot vote on them, these
                            proposals must conform to all or the majority of these three
                            criteria.
                            <br />
                            <br />
                            <p className="fw-bold">
                                Fight Against Centralisation,
                                <br />
                                Further the Cause of Decentralisation
                                <br />
                                Directly Benefit a Noble Cause.
                            </p>
                            5) These proposals can be from anywhere, VCs, community members,
                            politicians, anarchists, socialists, the right wing, as long as they
                            conform to the guidance above and it is a Guardian who submits it,
                            any proposal can be voted on by the Many. <br />
                            <br /> 6) A proposal must contain; the total supply of the investee
                            protocol token, the percentage of the total supply being offered in
                            return for 13 ETH investment, the tokenomics, the audit of the token
                            and any contracts if built, the burn and distribution plan <br />
                            <br />
                            7) The burn and distribution plan*1 is the vesting schedule for the
                            investee protocols token. For this example let us use a fake
                            protocol ABC DAO. The vesting schedule can be daily, weekly,
                            fortnightly or monthly, but cannot be vested over longer than 18
                            months at the very maximum. <br />
                            <br />
                            8) Lets say ABC DAO promises 1.2% of it’s $ABC token, vesting once a
                            month for 12 months. On the repayment date, rather than sending the
                            token of the investee protocol, like a normal VC funded protocol
                            would, the investee company instead swaps 0.1% of its supply (1.2%
                            over 12 months) of $ABC for $CULT. ABC DAO then sends half of the
                            cult to a burn wallet, and the other half to the DAO, which is paid
                            out to dCULT holders. <br />
                            <br />
                            9) If a proposal has been approved, and the 15.5 CULT ETH value is
                            hit, it will automatically send 13 ETH to the proposal wallet
                            address provided, and 2.5 ETH to a burn wallet. <br />
                            <br />
                            10) If a proposal is not approved, the CULT will continue to build
                            up past the 15.5 ETH level but will auto send once a proposal is
                            approved eventually
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="10">
                        <Accordion.Header>
                            Why can the Guardians submit proposals to the DAO, but not vote on
                            investment protocols?
                        </Accordion.Header>
                        <Accordion.Body>
                            The power in people, is stronger, than the people in power. This
                            cannot be forgotten.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11">
                        <Accordion.Header>
                            What type of decisions/ investments will CULT stakers pass votes on?
                            Will projects only be cryptocurrency/ blockchain based?
                        </Accordion.Header>
                        <Accordion.Body>
                            We leave the decisions, and the nature of the investee projects,
                            open to the Guardians and the Many. We offer only our vision &
                            guidance on what we created the protocol to be used for.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="12">
                        <Accordion.Header>
                            Do I have to stake my CULT when an investment protocol is agreed upon or can I decide not to stake?
                        </Accordion.Header>
                        <Accordion.Body>
                            If you would like to vote, then you must be staked.
                            You will also only receive rewards from the investee companies
                            if you are staked. However, you do not need to be staked to benefit
                            from CULT, every transaction furthers the cause and every burn decreases
                            the supply of the token, increasing the scarcity.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="13">
                        <Accordion.Header>
                            What criteria must be observed for a DAO proposal to be eligible for submission?
                        </Accordion.Header>
                        <Accordion.Body>
                            Our guidelines followed (Fight Against Centralisation, Further the Cause of Decentralisation, Directly Benefit a Noble Cause.)
                            <br />   <br />
                            1) The total supply of the investee protocol token<br />
                            2) The percentage of the total supply being offered in return for 13 ETH investment<br />
                            3) The tokenomics<br />
                            4) The audit of the token and any contracts if built<br />
                            5)<b> The burn and distribution plan*1</b>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="14">
                        <Accordion.Header>
                            What constitutes a ´Noble Cause´ in terms of investment?
                        </Accordion.Header>
                        <Accordion.Body>
                            “My fellow revolutionaries, liberation is a noble cause. We must fight to obtain it.”
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="15">
                        <Accordion.Header>
                            Why do we burn 2.5 ETH of every 15.5 ETH raised?
                        </Accordion.Header>
                        <Accordion.Body>
                            We are not blind to the blockchain and crypto markets core attraction being the potential
                            huge increases in price of a token picked by a user. We are also wise to the previous financial
                            systems tricks to keep the Many subdued and this is why our burn system has been designed
                            to make our economic model hyper deflationary,
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="16">
                        <Accordion.Header>
                            Why do we offer full distribution in the form of 50% burning and 50% proportional redistribution?
                        </Accordion.Header>
                        <Accordion.Body>
                            We the Many, who are for the people, put our faith IN the people.
                            We fund those willing to fight for our future and reward them with
                            our gratitude, community support and our trust. In turn, they sacrifice
                            half of the tokens we have purchased from them, and send them to a dead
                            wallet. The other half are rewarded to the Many for their contributions
                            they have put forward for the cause by transacting in CULT.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="17">
                        <Accordion.Header>
                            From the 50% redistributed to CULT stakers, users can then stake the CULT again into
                            dCULT. What is the difference between CULT and dCULT OR What is dCULT?
                        </Accordion.Header>
                        <Accordion.Body>
                            dCULT is the “proof of stake” token. It is non transferable. Only the user who
                            staked their cult will benefit from dCULT. Owning a proportion of dCULT when
                            profits are sent to the DAO allows you to claim your reward which is paid our
                            in proportion to your dCULT ownership.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="18">
                        <Accordion.Header>
                            Why is the investee token redistributed by converting it into CULT token?
                            How will the rates of disbursement be agreed upon?
                        </Accordion.Header>
                        <Accordion.Body>
                            The investee token is converted into CULT as it creates additional transactions,
                            as well as additional buying pressure in the market. The rate of disbursement
                            is offered by the investee protocol, a proposal can be brought about again once
                            rejected and so if the cause of the rejection is deemed to be due to an inadequate
                            disbursement offer, the investee protocol can fix and make these amends.


                        </Accordion.Body>
                    </Accordion.Item>
                    <div id="slide-content">
                        <Faq2 />
                    </div>

                </Accordion>
            </div>
        </div>
    );
}

export default Faq;
